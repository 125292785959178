// Generated by Framer (1b71f6a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VmqVQBIap", "AHrnsk8f9"];

const variantClassNames = {AHrnsk8f9: "framer-v-1jd4y0y", VmqVQBIap: "framer-v-1oskbok"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "VmqVQBIap", Mobile: "AHrnsk8f9"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "VmqVQBIap", title: f_b3V66Gk = "Naam club", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VmqVQBIap", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-65q1A", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1oskbok", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"VmqVQBIap"} ref={ref} style={{backgroundColor: "var(--token-0c3f76d2-28b9-4470-a1ca-46330b36daa2, rgb(255, 255, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} transition={transition} variants={{AHrnsk8f9: {borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4}}} {...addPropertyOverrides({AHrnsk8f9: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3V0Zml0LXJlZ3VsYXI=", "--framer-font-family": "\"Outfit\", serif", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>text</motion.p></React.Fragment>} className={"framer-he8sfx"} fonts={["GF;Outfit-regular"]} layoutDependency={layoutDependency} layoutId={"brlLNx56F"} style={{"--extracted-r6o4lv": "var(--token-2bcb91d6-cfc7-4829-a9c7-8f6372695f1a, rgb(102, 102, 102)) ", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={f_b3V66Gk} transition={transition} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({AHrnsk8f9: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7T3V0Zml0LXJlZ3VsYXI=", "--framer-font-family": "\"Outfit\", serif", "--framer-font-size": "14px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>text</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-65q1A [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-65q1A * { box-sizing: border-box; }", ".framer-65q1A .framer-16wxanb { display: block; }", ".framer-65q1A .framer-1oskbok { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; padding: 5px 12px 5px 12px; position: relative; width: min-content; }", ".framer-65q1A .framer-he8sfx { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-65q1A .framer-1oskbok { gap: 0px; } .framer-65q1A .framer-1oskbok > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-65q1A .framer-1oskbok > :first-child { margin-top: 0px; } .framer-65q1A .framer-1oskbok > :last-child { margin-bottom: 0px; } }", ".framer-65q1A.framer-v-1jd4y0y .framer-1oskbok { padding: 4px 12px 4px 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"AHrnsk8f9":{"layout":["auto","auto"]}}}
 * @framerVariables {"f_b3V66Gk":"title"}
 */
const FramerwWhDOq3q0: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerwWhDOq3q0;

FramerwWhDOq3q0.displayName = "Branches carousel company name";

FramerwWhDOq3q0.defaultProps = {height: 32, width: 100};

addPropertyControls(FramerwWhDOq3q0, {variant: {options: ["VmqVQBIap", "AHrnsk8f9"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, f_b3V66Gk: {defaultValue: "Naam club", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerwWhDOq3q0, [{family: "Outfit", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/wWhDOq3q0:default", url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf", weight: "400"}])